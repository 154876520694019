.logoContainer {
    display: grid;
    place-items: center;
    width: 100%;
    /* align-items: flex-start;
    padding-inline: 32px; */

}

.logo {
    /* width: 48px; */
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.footerTopItem {
    height: 56px;
    display: grid;
    place-items: center;
}

.icon {
    height: 100%;
    width: 100%
}

.socials {
    /* place-items: center; */
}

.footer {
  font-size: 16px ;
  font-weight: medium;
  font-family: var(--font2);
  background: #203649;
  /* position: absolute; */
  bottom: 0;
  right: 0;
   width: 100%;
   /* height: 620px; */
}

.selected {
    color: white !important;
}

.pages {
    padding-inline: 16px;
 }

@media (min-width: 905px) {
     
     .logoContainer  {
         width: 22%;
         justify-content: flex-start;
     }

     .pages {
         width: 65%;
     }

     .socials {
         width: 22%;
         justify-items: end;
     }

     .top {
        justify-content: space-between; 
     }

     .bottom {
         justify-content: space-between;
     }

     .footer {
         justify-content: space-between;
         padding: 32px;
         height: 400px;
          /* height: calc(100vh * 0.33); */
         font-size: 20px !important;
     }

    
}

@media (min-width: 1239px) {
     
    

    
    .footer {
        font-size: 20px ;
        
      }

   
}
@media screen and (min-width: 905px) and (max-width: 1239px) {
    .navbar {
        
        padding-inline: 20px;
    }
}

@media screen and (min-width: 1240px) and (max-width: 1439px) {
    .navbar {
        
        padding-inline: 5vw;
    }
}

@media(min-width: 1440px) {
    .navbar {
        
        padding-inline: 72px;
    }
    .navbar-lg {
        padding: 0 calc((100% - 1440px) / 2);
    }
 
}
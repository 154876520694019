.title {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font1);
    /* color: #b20020 !important; */
    color: var(--primary);
    /* font:  20px 600 var(--font1) !important; */
  }

  .searchBtn {
      height: 40px;
  }

  .subtitle {
        text-align: justify;
        font-size: 14px;
        font-weight: 400;
        font-family: var(--font2);
        /* line-height: 1.8; */
        /* font: 14px 600 var(--font1) !important; */
      
  }

  /* @media screen and (max-width: 1024px) {
    .carouselHeaderText {
      font-size: 40px !important;
      font-weight: bold !important;
    }
  } */

  @media screen and (min-device-width: 905px) {
    .carouselHeaderText {
      font-size: 65px !important;
      font-weight: bold !important;
    }
  }

  @media screen and (min-width: 1024px) {
     
    .title {
        font-size: 34px ;
         font-weight: bold;
        /* font:  20px 600 var(--font1) !important; */
      }
     
      .subtitle {
          font-size: 18px;
          font-weight: 500;
      }

      .carouselHeaderText {
        font-size: 65px !important;
        font-weight: bold !important;
      }

      .searchBtn {
        height: 50px !important;
        font-size: 18px !important;
    }
 
}


@media (min-width: 1440px) {
    .title {
        font-size: 34px ;
         /* font:  20px 600 var(--font1) !important; */
      }

      .subtitle {
        font-size: 18px;
     }
  
  }
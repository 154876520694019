.form {
    width: 100%;
}

.active {
    background-color: green;
}

.overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(54,192,84,0.12);
    z-index: 2;
    cursor: pointer;
}

.pressed {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(54,192,84,0.12);
    z-index: 2;
    cursor: pointer;
}

.selected {
    color: green !important;

     
}
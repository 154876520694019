
:root {
  /* --font1: 'Work Sans', sans-serif; */
  --font1: 'Inter', sans-serif;
  --font2: 'Nunito', sans-serif;
  --fontLogo: 'Audiowide', cursive;
  --primary: #262949;
  --secondary: #ec0f47;
  --darkSecondary: #b20020;
}
.whatsapp_widget_wrapper {
  z-index: 100;
}
.homePage-heading {
  font-size: 70px !important;
  font-weight: 500 !important;
  font-family: var(--font1) !important;
  /* color: var(--darkSecondary) !important; */
 }

 .homePage-search {
  /* font-size: 25px !important; */
  font-weight: 500 !important;
  font-family: var(--font1) !important;
  /* height: 63px !important; */
  
 }

 .intro-title {
   font-size: 57px;
   /* font-size: 30px; */

   font-family: var(--font1) !important;
   font-weight: bold;


 }
 .intro-text {
   font-size: 25px;
   font-family: var(--font2) !important;

 }

.h1 {
  font-size: 96px !important;
  font-weight: 300 !important;
  font-family: var(--font1) !important;
  /* color: var(--darkSecondary) !important; */
  color: var(--primary) !important;

  /* font: 96px 300 var(--font1) !important; */
}

.h2 {
  font-size: 60px !important;
  font-weight: 400 !important;
  font-family: var(--font1) !important;
  /* color: var(--darkSecondary) !important; */
  color: var(--primary) !important;


  /* font: 60px 400 var(--font1) !important; */
}

.h3 {
  font-size: 48px !important;
  font-weight: 600 !important;
  font-family: var(--font1) !important;
  /* color: var(--darkSecondary) !important; */
  color: var(--primary) !important;

 }

.h4 {
  font-size: 34px !important;
  font-weight: 600 !important;
  font-family: var(--font1) !important;
  color: var(--primary) !important;

  /* color: var(--darkSecondary) !important; */
 }

.h5 {
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: var(--font1) !important;
  color: var(--primary) !important;
  /* color: var(--darkSecondary) !important; */
 }

.h6 {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: var(--font1) !important;
  /* color: #b20020 !important; */
  color: var(--primary);
  /* font:  20px 600 var(--font1) !important; */
}

.subtitle1 {
  font-size: 16px !important;
  font-weight: medium !important;
  font-family: var(--font2) !important;
  /* font: 16px medium var(--font1) !important; */
}

.subtitle2 {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: var(--font2) !important;
  /* font: 14px 600 var(--font1) !important; */
}

.body1 {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: var(--font2) !important;
  text-align: justify;
}

.body2 {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: var(--font2) !important;
  text-align: justify;
}

.button {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: var(--font2) !important;
  /* font: 14px 600 var(--font1) !important; */
  text-transform: capitalize;
 }

.caption {
  font-size: 12px !important;
  font-weight: medium !important;
  font-family: var(--font2) !important;
  /* font: 12px medium var(--font1) !important; */
}

.overline {
  /* font: 12px 600 var(--font1) !important; */
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: var(--font2) !important;
  text-transform: uppercase;
}

body {
  /* background-color: #f5f5f7 !important; */
  /* background-color: #303f9f !important; */
  background-color: #fff !important;
}

view-bg {
  background-color: white;
}

.logoFont {
  font-family: var(--fontLogo) !important;
}

.active {
  color: var(--secondary) !important;
}

@media (min-width: 768px) {
  .body1 {
    font-size: 18px !important;
  }
  
  .body2 {
    font-size: 14px !important;
  }
}

@media (min-width: 1024px) {
  /* .h6 {
    font-size: 34px !important;
    font-weight: bold;
    }

   .subtitle2 {
    font-size: 20px !important;
    font-weight: 600;
  } */
}

@media (min-width: 1239px) {
  .body2 {
    font-size: 18px !important;
     
  }
}



@media (min-width: 1440px) {
  /* .h6 {
    font-size: 40px !important;
    font-weight: bold;
    }

   .subtitle2 {
     font-size: 30px;
   } */

   .caption {
    font-size: 13px !important;
    
    /* font: 12px medium var(--font1) !important; */
  }


}
   
  @media(min-width: 1441px) {
     .layout {
       width: 1440px;
       margin: 0 auto;
       padding-inline-start: 0 !important;
       padding-inline-end: 0 !important;
     }

     .navbar-lg {
      padding: 0 calc((100% - 1440px) / 2) !important;
     }

     .padding-lg {
      /* padding-inline-end:  calc((100% - 1440px) / 2) !important; */
      padding-inline:  calc((100% - 1440px) / 2) !important;
     }
  }


.slide {
    display: relative;
     
    
}

.slide .featuredText {
    height: 72px;
    width: 100%;
}

@media(max-width: 767px) {
    .mySwiper   {
        padding: 0 1em;
        /* background-color: red; */
        /* height: 30vh; */
    }
}

@media(min-width: 768px) {
    .mySwiper   {
        padding: 0 2em;
        /* background-color: red; */
        /* height: 30vh; */
    }
}

@media(min-width: 1024px) {
    .mySwiper   {
        padding: 0 5vw;
        /* background-color: red; */
        /* height: 30vh; */
    }
}

@media(min-width: 1444px) {
    .mySwiper   {
        padding: 0 calc((100% - 1440px) / 2);
        /* background-color: red; */
        /* height: 30vh; */
    }
}

 

/* 
.mySwiper .swiper-pagination-bullet {
    color: red;
} */

/* .mySwiper [active] {
    color: red;
} */